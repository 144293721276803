<template>
  <div class="titleInfoMain">
    <div class="ENGTitle Bold ">{{ titleInfo.ENGTitle }}</div>
    <div class="CHSTitle Medium animate__animated animate__fadeInUp">{{ titleInfo.CHSTitle }}</div>
    <div class="instructions animate__animated animate__fadeInUp" v-for="(item, index) in titleInfo.instructions">
      {{ item }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    titleInfo: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.titleInfoMain {
  width: 100%;
  text-align: center;
}
.ENGTitle {
  height: 62px;
  font-size: 54px;
  line-height: 74px;
  background: linear-gradient(180deg, #333333 0%, #ffffff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  opacity: 0.25;
}
.CHSTitle {
  font-size: 36px;
  color: #333333;
  line-height: 49px;
  margin-top: -16px;
  margin-bottom: 16px;
  letter-spacing: 3px;
}
.instructions {
  font-size: 14px;
  color: #333333;
  line-height: 26px;
  letter-spacing: 1px;
}
</style>
