<template>
  <div class="caseMain">
    <div class="caseBanner">
      <img-item imgName="christopher-gower-291246-unsplash" :styleObj="caseBannerImg" />
      <div class="bannerView">
        <img-item imgName="编组 8" :styleObj="bannerViewImg" />
        <!-- <div class="bannerViewTitle">More creativity</div> -->
        <div class="bannerViewLineBox"></div>
        <div class="bannerViewText">多创科技为您量身订做</div>
      </div>
    </div>
    <display-module :titleInfo="titleInfo" style="padding-top: 92px; margin-bottom: 56px" />

    <div ref="case1">
      <div class="caseItem animate__animated animate__fadeInRight" v-show="case1Show">
        <img-item imgName="网约车" :styleObj="caseItemImgRight" />
        <img-item imgName="编组 2 (6)" :styleObj="caseImg1" />
        <div class="caseItemContent">
          <div class="caseItemContentIndex">01</div>
          <div class="caseItemContentName Medium">网约车平台</div>
          <div class="caseItemContentText">
            协同多家网约车平台解决用户出行过程中“最后一公里”的痛点”，从根本上解决用户出行中遇到的实际困难，打造“铁路+网约车”一站式出行解决方案。
          </div>
          <!-- <div class="caseItemContentBotton Medium">了解详情</div> -->
        </div>
      </div>
    </div>

    <div ref="case2">
      <div class="caseItem animate__animated animate__fadeInLeft" v-show="case2Show">
        
        <div class="caseItemContent">
          <div class="caseItemContentIndex">02</div>
          <div class="caseItemContentName Medium">餐饮平台</div>
          <div class="caseItemContentText">
            餐饮平台针对旅客多样化、个性化的旅行服务需求，引进社会品牌餐食，能够充分发挥“互联网+”优势，为旅客提供更多的选择、更好的服务。
          </div>
          <!-- <div class="caseItemContentBotton Medium">了解详情</div> -->
        </div>
        <img-item imgName="餐饮平台" :styleObj="caseItemImgLeft" />
        <img-item imgName="编组 4 (2)" :styleObj="caseImg2" />
        <div></div>
      </div>
    </div>

    <div ref="case3">
      <div class="caseItem animate__animated animate__fadeInRight" v-show="case3Show">
        <img-item imgName="教育招生" :styleObj="caseItemImgRight" />
        <img-item imgName="截屏2020-12-17 下午3.30.24备份 (1)" :styleObj="caseImg3" />
        <div></div>
        <div class="caseItemContent">
          <div class="caseItemContentIndex">03</div>
          <div class="caseItemContentName Medium">
            泉港区义务教育招生信息管理平台
          </div>
          <div class="caseItemContentText">
            本平台以服务泉港区义务教育招生工作为宗旨，利用现代信息科技手段，旨在于为广大考生和家长、学校、基层招办提供高质量和高效率服务。
          </div>
          <!-- <div class="caseItemContentBotton Medium">了解详情</div> -->
        </div>
      </div>
    </div>

    <div ref="case4">
      <div class="caseItem animate__animated animate__fadeInLeft" v-show="case4Show">
        
        <div class="caseItemContent">
          <div class="caseItemContentIndex">04</div>
          <div class="caseItemContentName Medium">快乐学习积分系统</div>
          <div class="caseItemContentText">
            快乐学习积分系统针对学生课堂行为优化的游戏化课堂管理工具。班级优化大师为每一位学生设定了专属卡通角色，通过加减分、随机抽选进行角色升级，配合游戏化的规则、界面及音效，激发学生的好胜心与创造力。数据可自动记录、归档和计算，亦可一键发送至家长端。
          </div>
          <!-- <div class="caseItemContentBotton Medium">了解详情</div> -->
        </div>
        <img-item imgName="学习积分" :styleObj="caseItemImgLeft" />
        <img-item imgName="编组 6 (1)" :styleObj="caseImg4" />
        <div></div>
      </div>
    </div>

    <div class="caseMore Medium">
      <img-item imgName="rawpixel-714362-unsplash" :styleObj="caseMoreImg" />
      <div style="position: relative;z-index: 1;">更多产品案例，期待您与我们一起创造</div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import DisplayModule from "@/components/displayModule/index";
export default {
  name: "case",
  components: {
    DisplayModule
  },
  data() {
    return {
      titleInfo: {
        ENGTitle: "case",
        CHSTitle: "经典案例",
        instructions: [
          "多创科技精英团队，力求用最专业的技术和服务，为全国客户提供更精准高效的一站式互联网",
          "产品设计开发方案，为企业品牌赋能！"
        ]
      },
      caseBannerImg: {
        'width': '100%',
        'height': 'auto'
      },
      caseItemImgRight: {
        'position': 'absolute',
        'width': '992px',
        'height': '607px',
        'z-index': 0,
        'top': 0,
        'right': 0
      },
      caseItemImgLeft: {
        "position": 'absolute',
        'width': '992px',
        'height': '607px',
        'top': 0,
        'left': 0
      },
      bannerViewImg: {
        'width': '349x',
        'height': '200px',
        'margin': '0 auto 18px'
      },
      caseImg1: {
        "position": 'absolute',
        'width': '734px',
        'height': '524px',
        'top': '78px',
        'right': '649px'
      },
      caseImg2: {
        "position": 'absolute',
        'width': '861px',
        'height': '762px',
        'top': '-48px',
        'left': '563px'
      },
      caseImg3: {
        "position": 'absolute',
        'width': '791px',
        'height': '671px',
        'top': '-8px',
        'right': '610px'
      },
      caseImg4: {
        "position": 'absolute',
        'width': '819px',
        'height': '586px',
        'top': '29px',
        'left': '610px'
      },
      caseMoreImg:{
        "position": 'absolute',
        'top': 0,
        'left': 0,
        'right': 0,
        'bottom': 0,
        'width': '100%'
      },
      case1Show: false,
      case2Show: false,
      case3Show: false,
      case4Show: false
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      let _this = this;
      let refArray = [
        { ref: "case1", show: "case1Show" },
        { ref: "case2", show: "case2Show" },
        { ref: "case3", show: "case3Show" },
        { ref: "case4", show: "case4Show" }
      ];
      refArray.forEach((r, i) => {
        _this[r.show] = _this.gdjz(r.ref, 20);
      });
    },
    gdjz(div, offset) {
      let dom = this.$refs[div];
      if (dom) {
        var a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        return b + c > a;
      }
    }
  },
  created() { }
};
</script>
<style scoped>
.caseMain {
  width: 100%;
  overflow: hidden;
}

.caseBanner {
  width: 100%;
  position: relative;
}

.caseBannerImg {
  width: 100%;
  height: auto;
}

.bannerView {
  position: absolute;
  left: 50%;
  top: 188px;
  margin-left: -196px;
  /* width: 270px; */
  text-align: center;
}

/* .bannerViewImg {
  width: 83px;
  height: 83px;
  margin: 0 auto;
  background-image: url("https://vkceyugu.cdn.bspapp.com/VKCEYUGU-af6b216e-68d1-4cf6-b184-a411c26ab033/f22149cf-2fb3-4e35-9a52-78f718e491ae.png");
  background-position: -69px 0;
} */

.bannerViewTitle {
  font-size: 36px;
  font-family: DIN-BlackItalic, DIN;
  color: #ffffff;
  line-height: 68px;
  letter-spacing: 2px;
}

.bannerViewLineBox {
  width: 68px;
  height: 6px;
  background: #2b7bee;
  margin: 14px auto 12px;
}

.bannerViewText {
  font-size: 18px;
  color: #ffffff;
  line-height: 25px;
  letter-spacing: 1px;
}

.caseItem {
  width: 100%;
  height: 607px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 150px;
  margin-bottom: 140px;
}

/* .caseItemImgRight {
  position: absolute;
  width: 992px;
  height: 607px;
  z-index: 0;
  top: 0;
  right: 0;
} */

/* .caseItemImgLeft {
  position: absolute;
  width: 992px;
  height: 607px;
  top: 0;
  left: 0;
} */

.caseItemContent {
  position: relative;
  z-index: 1;
  width: 350px;
}

.caseItemContentIndex {
  font-size: 90px;
  font-family: DIN-BlackItalic, DIN;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.4);
  line-height: 109px;
  margin-top: 87px;
  letter-spacing: 7px;
}

.caseItemContentName {
  width: 443px;
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  line-height: 41px;
  margin-top: -42px;
}

.caseItemContentText {
  font-size: 18px;
  color: #ffffff;
  line-height: 26px;
  letter-spacing: 2px;
  margin: 43px 0 53px;
  opacity: 0.7;
}

.caseItemContentBotton {
  width: 150px;
  height: 45px;
  background: #2b7bed;
  border-radius: 23px;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
}

.caseMore {
  width: 100%;
  height: 270px;
  line-height: 270px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 4px;
  font-size: 36px;
  position: relative;
}

.caseItemImg {
  display: flex;
  align-items: center;
}

.absoluteBox {
  position: absolute;
}

/* .caseImg1 {
  width: 734px;
  height: 524px;
  top: 78px;
  right: 649px;
} */

/* .caseImg2 {
  width: 861px;
  height: 762px;
  top: -48px;
  left: 563px;
} */

/* .caseImg3 {
  width: 791px;
  height: 671px;
  top: -8px;
  right: 610px;
} */

/* .caseImg4 {
  width: 819px;
  height: 586px;
  top: 29px;
  left: 610px;
} */
</style>
